.nittro-dialog-holder {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0);
    transition: height 0.5s step-end, background 0.5s cubic-bezier(.27,.78,.2,.98);

    .nittro-dialog-inner {
        flex: 0 0 auto;
        min-width: 300px;
        max-height: 100vh;
        margin: 0 auto;
        padding: 0;
        overflow-y: auto;
        background: #fff;
        transform: translateY(-100%);
        transition: transform 0.3s ease-out;

        .nittro-dialog-content {
            padding: 30px 30px 15px;
        }

        .nittro-dialog-buttons {
            padding: 15px 30px 30px;
            text-align: center;
        }
    }

    &.visible {
        height: 100%;
        transition: height 0.3s step-start, background 1s ease-out;

        .nittro-dialog-inner {
            transform: translateY(0);
        }
    }

    &.topmost {
        background: rgba(0, 0, 0, 0.6);
    }
}
